.container.projects__container {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 4.2rem;
}

.project__container {
    width: 100%;
    position: relative;
    height: 350px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}

.project__container:nth-child(2) {
    flex-direction: row;
}

.project__image-container{
    display: block;
    border-radius: 0.2rem;
    position: absolute;
    width: 65%;
    border-radius: 0.3rem;
    transition: var(--transition);
    aspect-ratio: 2/1;
}

.project__image-container:hover {
    box-shadow: var(--shadow)
}

.project__image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #47b6ff80;
    pointer-events: none;
    transition: var(--transition);
    border-radius: 0.3rem;
}

.project__image {
    border-radius: 0.2rem;
    filter: blur(1px);
    transition: var(--transition);
    
}

.project__image img {
    border-radius: 0.3rem;
    height: 100%;
    object-position: top;
}

.project__image:hover {
    filter: blur(0);
}

.project__image:hover::before{
    opacity: 0;
}

.project__container:nth-child(1) >
.project__content,
.project__container:nth-child(3) >
.project__content {
    width: 60%;
    margin-right: auto;
}

.project__container:nth-child(2) >
.project__content {
    width: 60%;
    margin-left: auto;
    text-align: right;
}

.project__content h5{
    font-weight: 600;
}

.project__content-details {
    position: relative;
    margin: 1.2rem auto;
    padding: 1.7rem;
    border-radius: 0.3rem;
    background: var(--secondary-bg-color);
    box-shadow: 2px 0px 7px 1px rgba(0,0,0,0.5);
    display: block;
    font-size: 14.5px;
}

.project__content-techs {
    display: flex;
    gap: 1.2rem;
    font-size: 0.8rem;
    color: var(--light-color);
    font-weight: 500;
    user-select: none;
    position: relative;
    flex-wrap: wrap;
}

.project__container:nth-child(2) .project__content-techs {
    display: flex;
    gap: 1.2rem;
    font-size: 0.8rem;
    color: var(--light-color);
    font-weight: 500;
    user-select: none;
    position: relative;
    flex-wrap: wrap;
    justify-content: right;
}

.project__links {
    margin-top: 1.2rem;
    display: flex;
    gap: 1.2rem;
}

.project__container:nth-child(2) .project__links {
    justify-content: right;
}

.project__link-icon {
    font-size: 1.5rem;
    color: var(--light-color);
    transition: var(--transition);
}

.project__link-icon:hover {
    color: var(--primary-color);
}

/* =================== MEDIA QUERIES (LARGEST DEVICES) ============== */
@media screen and (min-width: 2000px) {
    .project__container {
        height: 450px;
    }
}

/* =================== MEDIA QUERIES (LARGE DEVICES) ============== */
@media screen and (max-width: 1700px) {
    .container.projects__container {
        width: var(--container-width-lg);
        gap: 5rem;
    }
}

/* =================== MEDIA QUERIES (MEDIUM DEVICES -> LARGE DEVICES) ============== */
@media screen and (min-width: 1025px) and (max-width: 1439px) {
    .container.projects__container {
        width: var(--container-width-md);
        gap: 10rem;
    }

    .project__container {
        height: auto;
    }

    .project__content {
        width: 69%;
    }
}

/* ================== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 1024px) {
    .container.projects__container {
        width: var(--container-width-md);
        gap: 2rem;
    }

    .project__container {
        height: auto;
    }

    .project__content {
        width: 69%;
    }
}

/* ================== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 800px) {
    .project__container {
        height: auto;
    }

    .project__image-container{
        position: absolute;
        width: 100%;
        top: 0;
        height: 100%;
    }

    .project__container:nth-child(1) > .project__content,
    .project__container:nth-child(2) > .project__content,
    .project__container:nth-child(3) > .project__content {
        width: 100%;
        height: 100%;
        padding: 2rem ;
        background: var(--overlay-bg-color);
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
    }

    .project__content-details {
        background-color: transparent;
        box-shadow: none;
        padding: 0;
    }

    .project__content:nth-child(2) 
    .project__content-techs,
    .project__content:nth-child(2) 
    .project__links{
        justify-content: left;
    }

    .project__content-techs {
        gap: 0.7rem;
    }
}
