@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    /* background-colors */
    --primary-bg-color: #0e1218;
    --secondary-bg-color: #162336;
    --overlay-bg-color: #162336b3;

    /* text-colors */
    --primary-color: #4db5ff;
    --primary-color-variant: #0870ba;
    --light-color: #ffffff99;
    --light-color-variant : #ffffffd2;
    --white: #ffffff;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;

    --transition: all .4s ease;
    --shadow: 2px 0px 7px 1px #4abdff4d;;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    width: 0.5rem;
    
}

::-webkit-scrollbar-track {
    background: var(--overlay-bg-color);
}

::-webkit-scrollbar-thumb {
    background: var(--primary-color-variant);
    transition: var(--transition);
    border-radius: 5px;
    max-height: 5px;
}

::-webkit-scrollbar-thumb:hover{
    background: var(--primary-color)
}

body {
    font-family: 'Poppins', sans-serif;
    background-color: var(--primary-bg-color);
    color: var(--light-color-variant);
    line-height: 1.7;
}

.no-scroll {
    overflow: hidden;
}

/* ================== GENERAL STYLES =============== */
.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
    color: var(--white);
}

h3 {
    font-size: 1.5rem;
}

section {
    padding-top: 10rem;
}

section > h2, section > h5 {
    text-align: center;
    color: var(--light-color);
}

section > h2 {
    color: var(--primary-color);
    margin-bottom: 4rem;
    font-size: 2rem;
}

.text-light {
    color: var(--light-color);
}

.text-primary {
    color: var(--primary-color-variant);
}

a {
    color: var(--white-color);
    transition: var(--transition)!important;
}

a:hover {
    color: var(--primary-color-variant);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--white-color)!important;
    padding: 0.75rem 1.2rem;
    cursor: pointer;
    border: 1px solid transparent;
    transition: var(--transition)!important;
    position: relative;
    overflow: hidden;
    text-shadow: 0px 2px 5px rgba(255, 255, 255, 0.51);
    letter-spacing: 1px;
}

.btn:hover {
    color: var(--white-color);
    background-color: var(--primary-color);
    border-color: var(--primary-color-variant);
}

.btn-primary {
    background-color: var(--primary-color-variant);
}

.running-light {
    position: absolute;
}

.running-light:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, var(--primary-color));
    animation: anim1 1s linear infinite;
}

@keyframes anim1 {
    0% {
        left: -100%;
    }
    50%, 100% {
        left: 100%;
    }
}

.running-light:nth-child(2) {
    top: -100%;
    right: 0%;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, var(--primary-color));
    animation: anim2 1s linear infinite;
    animation-delay: 0.25s;
}

@keyframes anim2 {
    0% {
        top: -100%;
    }
    50%, 100% {
        top: 100%;
    }
}

.running-light:nth-child(3) {
    bottom: 0;
    right: -100%;
    height: 2px;
    width: 100%;
    background: linear-gradient(270deg, transparent, var(--primary-color));
    animation: anim3 1s linear infinite;
    animation-delay: 0.5s;
}

@keyframes anim3 {
    0% {
        right: -100%;
    }
    50%, 100% {
        right: 100%;
    }
}

.running-light:nth-child(4) {
    bottom: -100%;
    left: 0%;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, var(--primary-color));
    animation: anim4 1s linear infinite;
    animation-delay: 0.75s;
}

@keyframes anim4 {
    0% {
        bottom: -100%;
    }
    50%, 100% {
        bottom: 100%;
    }
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}


/* ================== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

    section {
        padding-top: 6rem;
    }

    section,
    .container {
        overflow: hidden;
    }

}
/* ================== MEDIA QUERIES (Tablet DEVICES) =============== */
@media screen and (max-width: 800px) {
    ::-webkit-scrollbar {
        display: none;
    }
}
/* ================== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }

    section > h2 {
        margin-bottom: 2rem;
    }
}
