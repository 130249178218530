.container.others__container {
    width: 62%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.other__container {
    border: 1px solid transparent;
    padding: 2rem 1.5rem;
    background: var(--secondary-bg-color);
    border-radius: 5px;
    color: var(--light-color);
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: var(--transition);
}

.other__container:hover {
    box-shadow: 0px 0px 7px 2px rgba(74, 189, 255, 0.3);
}

.other__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.other__head-icon {
    font-size: 4rem;
    color: var(--primary-color-variant);
}

.other__head-links a {
    font-size: 1.5rem;
    margin-left: 1rem;
    color: var(--light-color);
    transition: var(--transition);
}

.other__head-links a:hover {
    color: var(--primary-color);
}

.other__details {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1rem;
    margin-bottom: 2rem;
}

.other__details h3 {
    color: var(--light-color-variant);
    transition: var(--transition);
    position: relative;
    padding-bottom: 0.2rem;
    font-size: 1.2rem;
}

.other__details h3::before {
    content: '';
    position: absolute;
    background: var(--primary-color-variant);
    width: 0%;
    height: 1px;
    transform: scaleX(1);
    bottom: 0;
    left: 0;
    transition: var(--transition);
}

.other__details h3:hover::before{
    width: 50%;
}

.other__details h3:hover {
    color: var(--primary-color);
}

.other__details p {
    font-size: 14.5px;
}

.other__tech {
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem;
    font-size: 0.8rem;
    font-weight: 500;
}

/* =================== MEDIA QUERIES (LARGER DEVICES) ============== */
@media screen and (max-width: 1440px) {
    .container.others__container {
        width: var(--container-width-lg);
    }
}

/* =================== MEDIA QUERIES (LARGER DEVICES) ============== */
@media screen and (max-width: 1024px) {
    .container.others__container {
        width: var(--container-width-sm);
        grid-template-columns: 1fr 1fr 1fr;
    }

    .other__container {
        padding: 1.5rem;
    }
}

/* =================== MEDIA QUERIES (LARGER DEVICES) ============== */
@media screen and (max-width: 800px) {
    .container.others__container {
        width: var(--container-width-md);
        grid-template-columns: 1fr 1fr;
    }
}

/* =================== MEDIA QUERIES (LARGER DEVICES) ============== */
@media screen and (max-width: 600px) {
    .container.others__container {
        grid-template-columns: 1fr;
    }
}