.about__container {
    display: grid;
    grid-template-columns: 35% 55%;
    gap: 10%;
    position: relative;
}

.about__me {
    width: 100%;
    border-radius: 0.5rem;
    background: linear-gradient(
        135deg, 
        transparent,
        var(--primary-color-variant),
        transparent
    );
    display: grid;
    place-items: center;
    position: relative;
    aspect-ratio: 1/1;
}

.about__me-image {
    width: 100%;
    border-radius: 0.5rem;
    transform: rotate(10deg);
    transition: var(--transition);
    background: linear-gradient(
        45deg, 
        transparent,
        var(--primary-color),
        transparent
    );
    position: relative;
    overflow: hidden;
    aspect-ratio: 1/1;
    padding: 1rem;
}

.about__me-image:hover {
    transform: rotate(0);
}

.about__content > p {
    margin-bottom: 1.2rem;
    color: var(--light-color);
}

.about__me-tech {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 220px));
    gap: 0px 10px;
    padding: 0px;
    margin: 1.2rem 0 2rem;
    user-select: none;
    transition: var(--transition);
}

.about__me-tech li:hover {
    color: var(--primary-color);
}

.about__me-tech-icon {
    color: var(--primary-color-variant);
}

/* =================== MEDIA QUERIES (MEDIUM DEVICES) ============== */
@media screen and (max-width: 1440px) {
    .container.about__container {
        width: var(--container-width-lg);
    }
}

/* =================== MEDIA QUERIES (MEDIUM DEVICES) ============== */
@media screen and (max-width: 1024px) {
    .container.about__container {
        width: var(--container-width-md);
    }

    .about__me-image {
        transform: rotate(0);
    }
}

/* =================== MEDIA QUERIES (LAPTOPS DEVICES) ============== */
@media screen and (max-width: 800px) {
    .container.about__container {
        width: var(--container-width-sm);
        margin: auto;
        grid-template-columns: 1fr;
        gap: 1rem;
        overflow: hidden;
    }
    .about__content {
        width: var(--container-width-md);
        margin: auto;
        position: relative;
    }

    .about__me {
        width: 50%;
        margin: 1.7rem auto;
    }

    .about__me-image {
        filter: blur(0);
    }

}
/* =================== MEDIA QUERIES (SMALL DEVICES) ============== */
@media screen and (max-width: 600px) {
    .about__me {
        width: 65%;
    }

    .about__content {
        width: var(--container-width-sm);
        margin: 0 auto;
    }
}